.footer {
  position: relative;
  z-index: 3;
  width: 100%;
  padding: 20px 5%;

  @media (max-width: 768px) {
    padding: 18px 10px;
  }

  .footer_bg {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
  }

  .PCDesign {
    position: relative;
    z-index: 5;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .Description {
      display: flex;
      justify-content: flex-start;

      .AgeSuccess {
        width: 50px;
        height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;

        @media(max-width: 768px) {
          width: 34px;
          height: 34px;
        }

        .border {
          position: absolute;
          width: 100%;
          height: 100%;
          object-fit: contain;
          top: 0;
          left: 0;

        }

        span {
          font-family: 'Roboto-Bold', sans-serif;
          font-weight: 700;
          font-size: 18px;
          line-height: 21px;

          @media(max-width: 768px) {
            font-size: 13px;
          }
        }
      }

      .TextFooter {
        width: 100%;
        max-width: 980px;
        padding-left: 13px;
        border-left: 1px solid #fff;
        margin-left: 20px;

        @media (max-width: 768px) {
          border: none;

          padding-left: 0;
          margin-left: 6px;
        }

        .Text {
          padding-top: 5px;
          font-size: 15px;
          line-height: 19px;
          font-weight: 400;
          font-family: 'Roboto-Regular', sans-serif;

          @media (max-width: 1300px) {
            font-size: 14px;
          }

          @media (max-width: 768px) {
            font-size: 10px;
          }

          a{
            font-family: 'Roboto-Medium', sans-serif;
            font-weight: 500;
          }
        }
      }
    }

    .FooterLogo {
      width: 192px;
      height: 39px;

      @media (max-width: 1300px) {
        width: 170px;
      }

      @media (max-width: 1150px) {
        display: none;
        border-radius: 10px;
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
  }
}

.Logo_AgeSuccess {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.Mobile_Logo {
  display: none;
  width: 72px;
  height: 15px;
  margin-top: 8px;

  @media(max-width: 768px) {
    display: block;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}