.ButtonsNavigationContainer {
  width: 100%;
  min-width: 380px;
  max-width: 380px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  margin-right: 50px;
  padding-right: 25px;

  @media (max-width: 1300px) {
    min-width: 360px;
    max-width: 360px;
  }

  @media (max-width: 1300px) {
    padding-right: 10px;
    min-width: 310px;
    max-width: 310px;
  }

  @media ( max-width: 768px) {
    margin-right: 0;
    min-width: 80%;
    max-width: 80%;
    padding: 0;
    margin: 0 auto;
  }

  @media ( max-width: 640px) {
    margin-right: 0;
    min-width: 100%;
    max-width: 100%;
    padding: 0;
  }

  &:after {
    content: '';
    position: absolute;
    width: 1px;
    height: 28px;
    right: 0;
    top: 50%;
    transform: translate(0, -50%);
    background: #fff;
    z-index: 3;

    @media ( max-width: 767px) {
      display: none;
    }
  }

  .Button {
    width: 100%;
    max-width: 180px;
    position: relative;

    @media (max-width: 1300px) {
      max-width: 160px;
    }

    @media (max-width: 1150px) {
      max-width: 160px;
    }

    @media ( max-width: 767px) {
      max-width: 45%;
      margin-bottom: 44px;
    }

    &_link {
      display: inline-block;
      width: 100%;
      height: 100%;
      text-align: center;
      font-size: 16px;
      line-height: 21px;
      font-weight: 600;
      padding: 5px 25px;
      text-transform: uppercase;
      text-decoration: none;
      outline: none;
      position: relative;
      z-index: 4;

      @media (max-width: 1300px) {
        font-size: 16px;
        line-height: 19px;
      }

      @media (max-width: 1300px) {
        font-size: 13px;
      }

      @media ( max-width: 767px) {
        font-size: 16px;
      }
    }

    &.active_page {

      background: linear-gradient(to right, orange, red),
      linear-gradient(to right, orange, red);
      background-position: var(--border-radius) 0, var(--border-radius) 100%;
      background-repeat: no-repeat;
      background-size: calc(100% - var(--border-radius) - var(--border-radius))
      var(--border-width);
      //padding: 5px 25px;
      border-radius: var(--border-radius);
      --border-width: 2px;
      --border-radius: 22px;
      --color-1: #f19f39;
      --color-2: #de222c;
      border: none;

      &:before,
      &:after{
        content: "";
        display: block;
        position: absolute;
        width: var(--border-radius);
        top: 0;
        bottom: 0;
      }

      &:before{
        left: 2px;
        border: var(--border-width) solid var(--color-1);
        border-top-left-radius: var(--border-radius);
        border-bottom-left-radius: var(--border-radius);
        border-right-color: transparent;
      }

      &:after {
        right: 2px;
        border: var(--border-width) solid var(--color-2);
        border-top-right-radius: var(--border-radius);
        border-bottom-right-radius: var(--border-radius);
        border-left-color: transparent;
      }
    }
  }
}