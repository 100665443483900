@font-face {
    font-family: 'Roboto-Bold';
    src: url('./Roboto-Bold.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
}
@font-face {
    font-family: 'Roboto-Medium';
    src: url('./Roboto-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}
@font-face {
    font-family: 'Roboto-Regular';
    src: url('./Roboto-Regular.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
}