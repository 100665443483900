.PageContainer {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 5%;
  z-index: 2;

  @media (max-width: 1150px) {
    flex-direction: column-reverse;
    justify-content: flex-start;
    padding: 0 3%;
  }

  @media ( max-width: 767px) {
    flex-direction: column;
    margin-bottom: 71px;
  }

  .LeftSide {
    width: 60%;
    max-width: 707px;

    @media (max-width: 1300px) {
      max-width: 650px;
    }

    @media (max-width: 1150px) {
      width: 80%;
      max-width: 100%;
    }

    @media ( max-width: 767px) {
      width: 100%;
    }

    .TitlePage {
      font-size: 70px;
      font-weight: 700;
      text-transform: uppercase;
      line-height: 87px;
      margin-bottom: 16px;
      font-family: 'Roboto-Bold', sans-serif;

      @media (max-width: 1300px) {
        font-size: 60px;
        line-height: 70px;
      }

      @media (max-width: 1150px) {
        font-size: 50px;
      }

      @media ( max-width: 767px) {
        font-size: 37px;
        line-height: 37px;
        text-align: center;
      }
    }

    .Subtitle {
      display: flex;
      flex-direction: column;
      margin-bottom: 39px;

      @media (max-width: 1150px) {
        margin-bottom: 40px;
      }

      @media ( max-width: 767px) {
        flex-direction: row;
        justify-content: center;
      }

      span {
        font-size: 23px;
        line-height: 35px;
        font-family: 'Roboto-Regular', sans-serif;

        @media (max-width: 1150px) {
          font-size: 20px;
          line-height: 27px;
        }

        @media ( max-width: 640px) {
          font-size: 14px;
          line-height: 16px;
          text-align: center;
        }
      }
    }

    .ButtonBlock {
      display: flex;
      justify-content: flex-start;
      margin-top: 33px;

      @media(max-width: 1150px) {
        justify-content: center;
      }

      @media ( max-width: 767px) {
        display: none;
      }

      .Button {

        &:first-child {
          margin-right: 31px;

          a {
            border: none;
            text-transform: uppercase;
            border-radius: 6px;
            background: var(--123, linear-gradient(267deg, #F87224 14.38%, #D9332E 101.09%));
            box-shadow: 0 3.308px 3.308px 0 rgba(0, 0, 0, 0.25);
            backdrop-filter: blur(14.473617553710938px);
            position: relative;
            overflow: hidden;

            &:before {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background: rgba(0, 0, 0, 0.2);
                opacity: 0;
                transition: opacity 0.3s ease-in-out;
            }

            &:hover {
              &:before {
                opacity: 1;
              }
            }
          }
        }

        &:last-child {
          a {
            border: 2px solid var(--123, #F87224);
            box-shadow: 0px 3.308px 3.308px 0px rgba(0, 0, 0, 0.25);
            backdrop-filter: blur(14.473617553710938px);
            border-radius: 5px;
            background: #000;
            transition: border-width 0.8s ease-in-out;
            box-sizing: border-box;
            position: relative;
            z-index: 5;

            &:hover {
              &:after {
                content: '';
                position: absolute;
                width: 100%;
                height: 100%;
                border: 4px solid var(--123, #F87224);
                box-shadow: 0px 3.308px 3.308px 0px rgba(0, 0, 0, 0.25);
                left: 0;
                top: 0;
                backdrop-filter: blur(14.473617553710938px);
                z-index: 4;
              }
            }
          }
        }

        a {
          width: 195px;
          display: inline-block;
          text-align: center;
          text-decoration: none;
          padding: 18px 0;
          font-family: 'Roboto-Medium', sans-serif;
          font-weight: 500;

          @media (max-width: 1300px) {
            font-size: 19px;
          }

          span {
            font-size: 21px;
            line-height: 24px;
            position: relative;
            z-index: 6;
          }
        }
      }
    }

    .StatisticsList {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      align-items: center;
      margin-top: 30px;

      @media (max-width: 1150px) {
        margin-top: 20px;
        margin-bottom: 20px;
        justify-content: center;
      }

      @media ( max-width: 767px) {
        display: none;
      }

      .BigText {
        font-size: 20px;
      }

      .StatisticsItem {
        padding: 6px 10px;
        margin-right: 16px;
        margin-bottom: 13px;
        border-radius: 15px;
        font-family: 'Roboto-Medium';
        font-size: 13px;
        line-height: 22px;
        background: #000;
        border: 1px solid #fff;

        .count {
          text-align: center;
          font-size: 28px;
          font-family: 'Roboto-Medium', sans-serif;
          font-weight: 500;
          margin-bottom: 5px;

          @media (max-width: 1300px) {
            font-size: 25px;
          }
        }

        .info {
          text-align: center;
          font-size: 15px;
          font-family: 'Roboto-Regular', sans-serif;
          font-weight: 400;
        }
      }
    }
  }

  .RightSide {
    width: 40%;
    max-width: 547px;

    @media (max-width: 1150px) {
      width: 80%;
      max-width: 100%;
      height: min-content;
      padding-top: 80px;
    }

    @media(max-width: 768px) {
      background: url("../../assets/images/mobile_bg_banner.png") no-repeat  0 0;
      background-size: cover;
      padding: 20px;
      border-radius: 10px;
    }

    .Banner {
      width: 100%;
      height: 660px;
      position: relative;

      @media (max-width: 1300px) {
        height: 600px;
      }

      @media (max-width: 1150px) {
        max-height: 600px;
      }

      @media ( max-width: 767px) {
        height: 413px;
      }

      &.MapScreen {
        max-width: 550px;
      }

      .BannerImage {
        width: 95%;
        height: 95%;
        object-fit: cover;
        border: 1px solid #de222c;

        @media(max-width: 768px) {
          border: none;
        }
      }

      .MapBorder {
        position: absolute;
        width: 101%;
        height: 101%;
        object-fit: contain;
        top: -3px;
        left: -2px;
      }
    }

    .TextBlock {
      position: relative;
      width: max-content;
      top: -40px;
      left: -150px;
      //background: rgb(124,124,124);
      //background: linear-gradient(180deg, rgba(124,124,124,.6) 9%, rgba(255,255,255,.5) 100%);
      border-radius: 6.563px;
      border: 1px solid #5D5D5D;

      background: linear-gradient(91deg, rgba(124, 124, 124, 0.23) -47.11%, rgba(255, 255, 255, 0.23) 110.32%);

      backdrop-filter: blur(15px);
      //border-radius: 13px;
      padding: 24px 15px;
      font-family: 'Roboto-Regular', sans-serif;
      overflow: hidden;

      @media (max-width: 1300px) {
        padding: 15px 10px;
        top: -80px;
        left: -20px;
        width: 400px;
      }

      @media (max-width: 1150px) {
        top: -50px;
        left: 50%;
        transform: translate(-50%, 0);
        width: max-content;
      }

      @media ( max-width: 767px) {
        display: none;
      }

      &:before {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        //background: rgba(255, 0, 255, 0.2);
        filter: blur(10px);
        border-radius: 13px;
        z-index: -1;
      }

      .DesctopContent {
        display: flex;
        align-items: flex-start;

        @media (max-width: 1150px) {
          top: -10px;
          left: 0;
          display: flex;
          flex-direction: row;

        }

        .TextBlockList {
          //margin-right: 30px;

          //@media (max-width: 1300px) {
          //  margin-right: 20px;
          //}

          .ListAboutText {
              display: flex;
              align-items: center;

            @media (max-width: 1300px) {
              flex-wrap: wrap;
              justify-content: center;
            }

            &_Item {
              width: 204px;
              max-width: 250px;
              font-size: 15px;
              line-height: 19px;
              text-align: center;
              background: #000;
              border: 1px solid #fff;
              padding: 7px 31px;
              margin-right: 18px;

              @media (max-width: 1300px) {
                margin-bottom: 15px;
                max-width: 200px;
                font-size: 16px;
                padding: 7px 9px
              }

              @media (max-width: 1150px) {
                margin-bottom: 0;
              }

              &:nth-child(2) {
                width: 100%;
                max-width: 179px;

                @media (max-width: 1300px) {
                  max-width: 150px;
                  margin-bottom: 0;
                }
              }

              &:last-child {
                width: 100%;
                max-width: 229px;
                margin-right: 0;

                @media (max-width: 1300px) {
                  margin-bottom: 0;
                  max-width: 200px;
                }
              }
            }
          }
        }

        .Text {
          font-size: 17px;
          font-weight: 600;
          line-height: 20px;

          @media (max-width: 1300px) {
            font-size: 15px;
          }
        }

        .Button {
          width: 100%;
          min-width: 165px;
          border-radius: 5px;

          @media (max-width: 1300px) {
            min-width: 130px;
          }

          a {
            display: inline-block;
            width: 100%;
            height: 100%;
            padding: 16px 0;
            font-size: 21px;
            line-height: 24px;
            text-align: center;
            text-decoration: none;
            border-radius: 4.962px;
            border: 1px solid #C2C2C2;
            background: linear-gradient(195deg, rgba(0, 0, 0, 0.50) 11.27%, rgba(0, 0, 0, 0.50) 90.4%);
            box-shadow: 0px 3.308px 3.308px 0px rgba(0, 0, 0, 0.25);
            backdrop-filter: blur(14.473617553710938px);
            font-family: 'Roboto-Regular', sans-serif;

            @media (max-width: 1300px) {
              font-size: 19px;
            }
          }
        }
      }
    }
  }
}

.MobileButtonBuy {
  display: none;

  @media (max-width: 768px) {
    display: flex;
    justify-content: center;
    margin-top: 17px;
  }

  .ContainerButton {
    width: 100%;
    margin: 0 12px;

    a {
      text-align: center;
      display: inline-block;
      width: 100%;
      font-size: 17px;
      line-height: 21px;
      text-transform: uppercase;
      text-decoration: none;
      font-weight: 400;
      padding: 17px 0;
      border-radius: 6px;
      background: var(--123, linear-gradient(267deg, #F87224 14.38%, #D9332E 101.09%));
      box-shadow: 0px 3.478px 3.478px 0px rgba(0, 0, 0, 0.25);
      backdrop-filter: blur(15px);
    }
  }
}