html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
    color: #fff;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
    display: block;
}
body {
    line-height: 1;
}
ul {
    list-style: none;
}
blockquote, q {
    quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
    content: '';
    content: none;
}
table {
    border-collapse: collapse;
    border-spacing: 0;
}

a,p,span,div {
    font-family: 'Alegreya', serif;
    font-family: 'Roboto-Regular', sans-serif;
}

*, *:after, *:before {
    box-sizing: border-box;
}
/**{*/
/*    border: 1px solid red !important;*/
/*}*/

html, body {
    width: 100%;
    height: 100%;
    position: relative;
    overflow-x: hidden;
    background: url("../images/background-desctop.png");
    background-size: cover;
}

@media (max-width: 768px) {
    html, body {
        background: url("../images/bg-mobile.png");
        background-size: cover;
    }
}

.BackgroundImages {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
}

@media (max-width: 767px) {
    .BackgroundImages {
        width: 100%;
        height: max-content;
    }
}


#root {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

@media ( max-width: 767px) {
    #root {
        display: block;
        justify-content: space-between;
    }
}

.containerComponents {
    width: 100%;
    padding: 0 5%;

    @media ( max-width: 767px) {
        padding: 0 8%;
    }
}

@media (max-width: 1150px) {
    .containerComponents {
        padding: 0 2%;
    }
}

.PageContainer {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 5%;
    z-index: 2;
}