.PageContainer {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 5%;
  z-index: 2;

  .TitlePage {
    font-size: 75px;
    font-weight: 700;
    text-transform: uppercase;
    line-height: 87px;
    margin-bottom: 16px;
    font-family: 'Roboto-Bold', sans-serif;
    position: relative;
    z-index: 5;

    @media (max-width: 1300px) {
      line-height: 50px;
      margin-bottom: 10px;
    }

    @media (max-width: 767px) {
      font-size: 30px !important;
      line-height: 33px;
      text-align: center;
    }
  }

  .Subtitle {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;

    @media ( max-width: 767px) {
      flex-direction: row;
      justify-content: center;
    }

    span {
      font-size: 27px;
      line-height: 35px;
      font-family: 'Roboto-Regular', sans-serif;

      @media (max-width: 1300px) {
        font-size: 25px;
        line-height: 29px;
      }

      @media ( max-width: 767px) {
        font-size: 14px;
        line-height: 23px;
      }
    }
  }

  @media (max-width: 1150px) {
    flex-direction: column;
    justify-content: flex-start;
    padding: 0 3%;
  }

  @media ( max-width: 767px) {
    flex-direction: column;
    //padding-bottom: 71px;
    min-height: 550px;
  }

  .LeftSide {
    width: 60%;
    max-width: 707px;

    @media (max-width: 1150px) {
      padding-top: 40px;
      width: 80%;
      max-width: 100%;
      margin: 0 auto;
    }

    @media ( max-width: 767px) {
      width: 100%;
    }

    .TitlePage {
      font-size: 75px;
      font-weight: 700;
      text-transform: uppercase;
      line-height: 87px;
      margin-bottom: 16px;
      font-family: 'Roboto-Bold', sans-serif;

      @media (max-width: 1300px) {
        line-height: 50px;
        margin-bottom: 10px;
      }

      @media (max-width: 767px) {
        font-size: 37px !important;
        line-height: 37px;
        text-align: center;
      }

      &.Buy {
        font-size: 55px;

        @media (max-width: 1300px) {
          font-size: 45px;
        }
      }

    }

    .Subtitle {
      display: flex;
      flex-direction: column;
      margin-bottom: 39px;

      @media ( max-width: 767px) {
        flex-direction: row;
        justify-content: center;
      }

      span {
        font-size: 27px;
        line-height: 35px;
        font-family: 'Roboto-Regular', sans-serif;

        @media (max-width: 1300px) {
          font-size: 22px;
          line-height: 26px;
        }

        @media ( max-width: 767px) {
          font-size: 13px;
          line-height: 15px;
        }
      }
    }
  }

  .RightSide {
    width: 100%;
    margin-bottom: 25px;
    //max-width: 547px;

    //@media (max-width: 1300px) {
    //  max-width: 520px;
    //}
    //
    //@media (max-width: 1150px) {
    //  width: 80%;
    //  max-width: 100%;
    //}

    @media(max-width: 768px) {
      background: url("../../assets/images/mobile_bg_banner.png") no-repeat  0 0;
      background-size: cover;
      padding: 20px;
      border-radius: 10px;
    }

    .TitlePage {
      font-size: 75px;
      font-weight: 700;
      text-transform: uppercase;
      line-height: 87px;
      margin-bottom: 16px;
      font-family: 'Roboto-Bold', sans-serif;
      position: relative;
      z-index: 5;

      @media (max-width: 1300px) {
        line-height: 50px;
        margin-bottom: 10px;
      }

      @media (max-width: 767px) {
        font-size: 37px !important;
        line-height: 37px;
        text-align: center;
      }


      //&:after {
      //  content: '';
      //  position: absolute;
      //  background: url('../../assets/images/border_image.png');
      //  background-repeat: no-repeat;
      //  background-position: 0 0;
      //  width: 100%;
      //  height: 110%;
      //  left: -20px;
      //  top: -30px;
      //  background-size: contain;
      //  z-index: 2;
      //
      //  @media(max-width: 990px) {
      //    display: none;
      //  }
      //}

      //&:before {
      //  content: '';
      //  position: absolute;
      //  background: url('../../assets/images/border_image.png');
      //  background-repeat: no-repeat;
      //  background-position: 0 0;
      //  width: 100%;
      //  height: 110%;
      //  left: -10px;
      //  top: -20px;
      //  background-size: contain;
      //  z-index: 2;
      //
      //  @media(max-width: 990px) {
      //    display: none;
      //  }
      //}

      &.Buy {
        font-size: 55px;

        @media (max-width: 1300px) {
          font-size: 45px;
        }
      }

    }

    .Subtitle {
      display: flex;
      flex-direction: column;
      margin-bottom: 39px;
      max-width: 660px;

      @media ( max-width: 767px) {
        flex-direction: row;
        justify-content: center;
        text-align: center;
        width: 100%;
      }

      span {
        font-size: 27px;
        line-height: 35px;
        font-family: 'Roboto-Regular', sans-serif;

        @media (max-width: 1300px) {
          font-size: 22px;
          line-height: 26px;
        }

        @media ( max-width: 767px) {
          font-size: 13px;
          line-height: 15px;
        }
      }
    }

    .Banner {
      width: 100%;
      height: 620px;
      position: relative;

      &.MapScreen {
        //max-width: 500px;

        @media (max-width: 1300px) {
          //max-width: 450px;
        }

        @media(max-width: 1150px) {
          //margin: 0 auto;
        }

        @media (max-width: 760px) {
          //height: 355px;
          margin: 0 auto;
        }
      }

      .BannerImage {
        width: 100%;
        height: 100%;
        object-fit: contain;

        @media (max-width: 768px) {
          object-fit: fill;
        }
      }

      .MapBorder {
        position: absolute;
        width: 101%;
        height: 101%;
        object-fit: contain;
        top: -3px;
        left: -2px;
      }
    }

    .TextBlock {
      position: relative;
      top: -40px;
      left: -50px;
      background: rgb(124,124,124);
      background: linear-gradient(180deg, rgba(124,124,124,.6) 9%, rgba(255,255,255,.5) 100%);
      border-radius: 13px;
      padding: 24px 15px;
      font-family: 'Roboto-Regular', sans-serif;
      font-weight: 400;
      overflow: hidden;

      &:before {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: rgba(255, 0, 255, 0.2);
        filter: blur(10px);
        border-radius: 13px;
        z-index: -1;
      }

      .DesctopContent {
        display: flex;
        align-items: flex-start;

        .TextBlockList {
          margin-right: 30px;
        }

        .Text {
          font-size: 17px;
          font-weight: 600;
          line-height: 20px;
        }

        .Button {
          width: 100%;
          min-width: 160px;
          border-radius: 5px;
          -webkit-box-shadow: 6px 8px 15px -7px rgba(0,0,0,0.75);
          -moz-box-shadow: 6px 8px 15px -7px rgba(0,0,0,0.75);
          box-shadow: 6px 8px 15px -7px rgba(0,0,0,0.75);

          a {
            display: inline-block;
            width: 100%;
            height: 100%;
            padding: 16px 0;
            font-size: 21px;
            line-height: 24px;
            text-align: center;
            text-decoration: none;
            border: 1px solid #3DB8A5;
            background: #3DB8A5;
            background: linear-gradient(180deg, #3DB8A5, #055F3F);
            border-radius: 5px;
            font-family: 'Roboto-Regular', sans-serif;
            font-weight: 400;
          }
        }
      }
    }
  }
}

.Adress {
  width: 100%;
  max-width: 800px;
  position: relative;

  @media(max-width: 1150px) {
    overflow-x: scroll;
    margin: 20px auto 0;
  }

  .Adress_List {
    display: flex;
    flex-direction: column;

    @media(max-width: 1150px) {
      width: max-content;
      display: flex;
      justify-content: flex-start;
      flex-direction: row;
    }

    @media(max-width: 760px) {
      width: 100%;
      flex-direction: column;
      height: 118px;
      overflow: scroll;
    }

    .line {
      width: 100%;
      display: flex;

      @media(max-width: 1150px){
        display: block;
        width: 100%;
        margin-right: 15px;
      }

      @media(max-width: 768px){
        display: flex;
        flex-direction: column;
        width: 100%;
        margin-right: 0;
      }
    }

    .item {
      width: 50%;
      display: flex;
      flex-direction: column;
      margin-bottom: 6px;
      padding-right: 5px;

      @media(max-width: 1150px) {
        width: 300px;
        margin-bottom: 20px;
        margin-right: 15px;
      }

      @media(max-width: 768px) {
        width: 100%;
        margin-bottom: 9px;
        margin-right: 0;
        text-align: center;
      }
    }
  }

  &_mag {
    color: rgba(255, 255, 255, .7);
    font-size: 15px;
    font-family: 'Roboto-Regular', sans-serif;
    font-weight: 400;
    line-height: 18px;

    @media(max-width: 1150px) {
      font-size: 12px;
      line-height: 16px;
    }

    @media(max-width: 768px) {
      font-size: 9px;
      line-height: 12px;
    }
  }

  &_jour {
    font-size: 16px;
    line-height: 26px;
    font-weight: 500;

    @media (max-width: 1300px) {
      font-size: 20px;
    }

    @media(max-width: 1150px) {
      font-size: 18px;
      line-height: 22px;
    }

    @media(max-width: 768px) {
      font-size: 15px;
      line-height: 18px;
    }
  }
}

.Mobile_info {

  display: none;

  @media(max-width: 1150px) {
    display: block;
  }

  p {
    color: #B0B0B0;
    margin-top: 14px;
    font-size: 13px;
    line-height: 17px;
    display: flex;
    align-items: center;
    justify-content: center;

    @media(max-width: 768px) {
      color: #B0B0B0;
      margin-top: 32px;
      font-size: 10px;
      line-height: 14px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    img {
      display: inline-block;
      margin-left: 10px;
      transform: rotate(90deg);
    }
  }
}