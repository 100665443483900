.Header {
  position: relative;
  z-index: 2;
  padding-top: 20px !important;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 72px;

  @media (max-width: 1150px) {
    display: flex;
    //flex-direction: column-reverse;
  }

  @media ( max-width: 767px) {
    margin-bottom: 40px;
  }

  .logo {
    width: 100%;
    max-width: 278px;
    height: 54px;

    @media (max-width: 1300px) {
      max-width: 240px;
    }

    @media ( max-width: 767px) {
      max-width: 176px;
      height: 34px;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
}

.NavigateBlockHeader {
  display: flex;

  .Telegram {
    width: 100%;
    max-width: 150px;
    min-width: 150px;
    background: #000;
    border-radius: 5px;
    border: 1px solid #fff;
    transition: background 1s ease;

    &:hover {
      background: #fff;
      .icon_black {
        display: inline-block;
      }
      .icon_white {
        display: none;
      }
    }

    .icon_black {
      display: none;
    }

    .icon_white {
      display: block;
    }

    @media ( max-width: 767px) {

    }

    &_link {
      font-size: 13px;
      line-height: 16px;
      text-decoration: none;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 11px 0;
      border-radius: 5px;

      &:hover {
        border-radius: 5px;
        color: #000;
      }

      img {
        margin-left: 4px;
        display: inline-block;
        width: 18px;
        height: 15px;
        object-fit: contain;
      }
    }
  }
}